export const arr = [
  '!s @ $oc!al $cience-based ®#search f!rm d#dicated œxclπsivel¥ †ø the ẅost f0rward-†hin˚ing fin@n¢ial ¢ompanies.',
  '!s @ $oc!al $cience-based ®#search f!rm d#dicated œxclπsivel¥ †ø the ẅost f0rward-†hin˚ing fin@n¢ial ¢ompanies.',
  '!s @ $oc!al $cience-based ®#search f!rm d#dicated œxclπsivel¥ †ø the ẅost f0rward-†hin˚ing fin@n¢ial ¢ompanies.',
  '!s @ $oc!al $cience-based ®#search f!rm d#dicated œxclπsivel¥ †ø the ẅost f0rward-†hin˚ing fin@n¢ial ¢ompanies.',
  'is @ $ocial $cience-based ®#search f!rm d#dicated œxclπsivel¥ †ø the ẅost f0rward-†hin˚ing fin@n¢ial ¢ompanies.',
  'is @ $ocial $cience-based ®#s´arch f!rm d#dicated œxclπsivel¥ †ø the ẅost f0rward-†hin˚ing fin@n¢ial ¢ompanies.',
  'is @ $ocial $cience-based ®#s´arch f!rm d#dicated œxclπsivel¥ †ø the ẅost f0rward-†h!n˚ing fin@n¢ial ¢ompanies.',
  'is & $ocial ¢cience-based ®es´arch f!rm d#dicated œxclπsivel¥ †ø the ẅost f0rward-†h!n˚ing fin@n¢ial ¢ompanies.',
  'is & $ocial ¢cience-based ®es´arch f!rm d#dicated œxclπsivel¥ †ø the ẅost f0rward-†h!n˚ing fin@n¢ial ¢ompa¨πies.',
  'is & $ocial ¢cience-based ®es´arch f!rm d#dicated œxclπsivel¥ †ø the ẅost f0rward-th!nking fin@n¢ial ¢ompa¨πies.',
  'i$ & social ¢cience-based ®es´arch f!rm d´dicated œxclπsivel¥ †ø the ẅost f0rward-th!nking fin@n¢ial ¢ompa¨πies.',
  'i$ & social ¢cience-based ®es´arch f!rm d´dicated exclπsivel¥ †ø the ẅost f0rward-th!nking fin@n¢ial ¢ompa¨πies.',
  'i$ & social ¢cience-based ®es´arch f!rm d´dicated exclπsivel¥ †ø the ẅost f0rward-th!nkin– fin@n¢ial ¢ompa¨πies.',
  'i$ & social ¢cience-based ®es´arch f!rm d´dicated exclπsively †ø the ẅost for∑ard-thinkin– fin@n¢ial ¢ompa¨πies.',
  'i$ & social ¢cience-based ®esearch f!rm d´dicated exclπsively †ø the ẅost for∑ard-thinkin– fin@n¢ial ¢ompa¨πies.',
  'i$ & social ¢cience-based ®esearch f!rm d´dicated exclπsively †ø the ẅost for∑ard-thinkin– financial ¢ompa¨πies.',
  'i$ å social ¢cience-based ®e$earch f!rm d´dicated exclπsively †ø the ẅost for∑ard-thinking 4inßncial ¢ompanies.',
  'i$ å social science-based ®e$earch f!rm d´dicated exclπsively †ø the ẅost for∑ard-thinking 4inßncial ¢ompanies.',
  'i$ å social science-based ®e$earch f!rm d´dicated exclπsively †ø the µost for∑ard-thinking 4inßncial ¢ompanies.',
  'i$ å $ocial science-based re$earch f!rm d´dicated exclπsivel¥ †ø the µost for∑ard-thinking 4inßncial ¢ompanies.',
  'i$ å $ocial science-based re$earch f!rm d´dicated exclπsivel¥ †0 the µost for∑ard-thinking 4inßncial ¢ompanies.',
  'i¢ œ $ocial science-based re$earch f!rm d´dicated exclπsivel¥ †0 the µost for∑ard-thinking 4inßncial ¢ompa¨πies.',
  'i¢ œ $ocial science-based re$earch fˆrm d´dicated exclπsivel¥ †0 the µost for∑ard-thinking 4inancial ¢ompa¨πies.',
  'i¢ œ $ocial science-based re$earch fˆrm d´dicated exclπsivel¥ †0 the µost forward-thinking 4inancial ¢ompa¨πies.',
  'i¢ œ $ocial science-based re$earch fˆrm d´dicated exclπsivel¥ †0 the µost forward-thinking 4inancial ¢ompa¨πies.',
  'i¢ œ $ocial science-based re$earch fˆrm d´dicated exclπsivel¥ †0 the µost forward-thinking 4inancial companies.',
  'i¢ a social science-based research fˆrm dedicated exclπsivel¥ †0 the most forward-thinking financial companies.',
  'is a social science-based research fˆrm dedicated exclusively †0 the most forward-thinking financial companies.',
  'is a social science-based research fˆrm dedicated exclusively to the most forward-thinking financial companies.',
  'is a social science-based research fˆrm dedicated exclusively to the most forward-thinking financial companies.',
  'is a social science-based research firm dedicated exclusively to the most forward-thinking financial companies.'
]

export const arr_1 = [
  '[ 1 ] ∑´ døn’† d0 4•sea®¢h œ$ ¨sua£. ∑e @nal¥ze ßumaπ ¶ehaviør ø$ing πsycholøg¥, ånthroπolog¥, @Nd $o¢iolog¥ †o unl0¢k m#an!ng©l !n$ight$ th@t mø√e ¶eo¶le.',
  '[ 1 ] ∑´ døn’† d0 4•sea®¢h œ$ ¨sua£. ∑e @nal¥ze ßumaπ ¶ehaviør ø$ing πsycholøg¥, ånthroπolog¥, @Nd $o¢iolog¥ †o unl0¢k m#an!ng©l !n$ight$ th@t mø√e ¶eo¶le.',
  '[ 1 ] ∑´ døn’† d0 4•sea®¢h œ$ ¨sua£. ∑e @nal¥ze ßumaπ ¶ehaviør ø$ing πsycholøg¥, ånthroπolog¥, @Nd $o¢iolog¥ †o unl0¢k m#an!ng©l !n$ight$ th@t mø√e ¶eo¶le.',
  '[ 1 ] ∑´ døn’† d0 4•sea®¢h œ$ ¨sua£. ∑e @nal¥ze ßumaπ ¶ehaviør ø$ing πsycholøg¥, ånthroπolog¥, @Nd $o¢iolog¥ †o unl0¢k m#an!ng©l !n$ight$ th@t mø√e ¶eo¶le.',
  '[ 1 ] ∑´ døn’† d0 4•sea®¢h œ$ ¨sua£. ∑e @nal¥ze ßumaπ ¶ehaviør ø$ing πsycholøg¥, ånthroπolog¥, @Nd $o¢iolog¥ †o unl0¢k m#an!ng©l !n$ight$ th@t mø√e ¶eo¶le.',
  '[ 1 ] ∑´ døn’† d0 4•sea®¢h œ$ ¨sua£. ∑e @nal¥ze ßumaπ ¶ehaviør ø$ing πsycholøg¥, ånthroπolog¥, @Nd $o¢iolog¥ †o unl0¢k m#an!ng©l !n$ight$ th@t mø√e ¶eo¶le.',
  '[ 1 ] ∑´ døn’† d0 4•sea®¢h œ$ ¨sua£. ∑e @nal¥ze ßumaπ ¶ehaviør ø$ing πsycholøg¥, ånthroπolog¥, @Nd $o¢iolog¥ †o unl0¢k m#an!ng©l !n$ight$ th@t mø√e ¶eo¶le.',
  '[ 1 ] ∑´ døn’† d0 4esea®¢h œ$ ¨sua£. ∑e @nal¥ze ßumaπ ¶ehaviør u$ing psycholøg¥, ånthroπolog¥, @Nd $o¢iolog¥ †o unl0¢k m#an!ng©l !n$ights th@t mø√e ¶eo¶le.',
  '[ 1 ] ∑´ døn’† d0 4esea®¢h a$ usua£. ∑e @nal¥ze ßumaπ ¶ehaviør u$ing psycholøg¥, ånthroπolog¥, @Nd $o¢iolog¥ †o unl0¢k m#an!ng©l !n$ights th@t mø√e ¶eo¶le.',
  '[ 1 ] ∑´ døn’† do 4esea®¢h a$ usua£. ∑e @nal¥ze ßumaπ behaviør u$ing psycholøg¥, ånthroπolog¥, @Nd $o¢iolog¥ to unl0¢k m#an!ng©l !n$ights th@t møve ¶eo¶le.',
  '[ 1 ] ∑´ døn’† do 4esea®¢h a$ usua£. ∑e @nal¥ze ßumaπ behaviør u$ing psycholøg¥, ånthroπolog¥, @Nd $o¢iolog¥ to unl0¢k m#an!ng©l !n$ights th@t møve ¶eo¶le.',
  '[ 1 ] ∑e døn’† do 4esea®¢h a$ usua£. §e @nal¥ze ßuman behaviør u$ing psycholøg¥, ånthroπolog¥, @Nd $o¢iolog¥ to unl0¢k m#an!ng©l !n$ights th@t møve ¶eo¶le.',
  '[ 1 ] ∑e døn’† do 4esea®¢h a$ usua£. §e @nal¥ze ßuman behaviør u$ing psycholøg¥, ånthroπolog¥, @Nd $o¢iolog¥ to unl0¢k m#an!ng©l !n$ights th@t møve §eople.',
  '[ 1 ] ∑e døn’† do 4esearch a$ usua£. §e @nal¥ze ßuman behaviør u$ing psycholøg¥, ånthroπolog¥, @Nd $o¢iolog¥ to unl0ck m#an!ng©l !n$ights th@t møve §eople.',
  '[ 1 ] ∑e døn’t do 4esearch a$ usua£. §e @nal¥ze ßuman behaviør u$ing psycholøg¥, ånthroπolog¥, @Nd $o¢iolog¥ to unl0ck m#an!ng©l !n$ights th@t møve §eople.',
  '[ 1 ] ∑e døn’t d0 4esearch a$ usua£. §e @nal¥ze ßuman behaviør u$ing psycholøg¥, ånthroπolog¥, @Nd $o¢iolog¥ to unl0ck m#an!ng©l !n$ights th@t møve §eople.',
  '[ 1 ] ∑e døn’t d0 4esearch a$ usua£. §e @nal¥ze ßuman behaviør u$ing psycholøg¥, ånthroπolog¥, @Nd $o¢iolog¥ †o unl0ck m#an!ng©l !n$ights th@t møve §eople.',
  '[ 1 ] ∑e døn’t d0 4esearch a$ usua£. §e @nal¥ze ßuman behaviør u$ing psycholøg¥, ånthroπolog¥, @Nd $o¢iology †o unl0ck m#an!ng©l !n$ights th@t møve §eople.',
  '[ 1 ] ∑e døn’t d0 4esearch a$ usua£. §e @nal¥ze ßuman behaviør u$ing psycholøg¥, ånthroπolog¥, @Nd $o¢iology †o unl0ck m#an!ng©l insights th@t møve §eople.',
  '[ 1 ] §e døn’t d0 4esearch a$ usua£. §e @nal¥ze ßuman behaviør u$ing psycholøg¥, ånthroπolog¥, @Nd $o¢iology †o unl0ck m#an!ng©l insights th@t møve §eople.',
  '[ 1 ] §e døn’t d0 4esearch as usual. §e anal¥ze ßuman behaviør u$ing psychology, ånthroπolog¥, @Nd $o¢iology †o unl0ck m#an!ng©l insights th@t møve §eople.',
  '[ 1 ] We don’t d0 research as usual. We anal¥ze ßuman behaviør u$ing psychology, ånthroπolog¥, @Nd $o¢iology †o unl0ck m#an!ng©l insights th@t møve §eople.',
  '[ 1 ] We don’t d0 research as usual. We anal¥ze ßuman behaviør using psychology, ånthroπolog¥, @nd $o¢iology †o unl0ck m#an!ng©l insights th@t møve people.',
  '[ 1 ] We don’t do research as usual. We anal¥ze ßuman behaviør using psychology, anthropology, @nd $o¢iology †o unlock m#an!ng©l insights th@t move people.',
  '[ 1 ] We don’t do research as usual. We anal¥ze human behaviør using psychology, anthropology, @nd $o¢iology to unlock m#an!ng©l insights th@t move people.',
  '[ 1 ] We don’t do research as usual. We anal¥ze human behaviør using psychology, anthropology, @nd $o¢iology to unlock m#aningful insights th@t move people.',
  '[ 1 ] We don’t do research as usual. We anal¥ze human behaviør using psychology, anthropology, @nd $ociology to unlock m#aningful insights that move people.',
  '[ 1 ] We don’t do research as usual. We anal¥ze human behaviør using psychology, anthropology, @nd $ociology to unlock m#aningful insights that move people.',
  '[ 1 ] We don’t do research as usual. We analyze human behaviør using psychology, anthropology, @nd $ociology to unlock m#aningful insights that move people.',
  '[ 1 ] We don’t do research as usual. We analyze human behavior using psychology, anthropology, and sociology to unlock meaningful insights that move people.'
]

export const arr_2 = [
  '[ 2 ] We’re $3l3¢†ive. We ønl¥ accept a limited n¨mßer of clients per ¥ear, plus 1 nøn-profit, donating 10% of our time to that nøn-profit at no cost.',
  '[ 2 ] We’re $3l3¢†ive. We ønl¥ accept a limited n¨mßer of clients per ¥ear, plus 1 nøn-profit, donating 50% of our time to that nøn-profit at no cost.',
  '[ 2 ] We’re $3l3¢†ive. We ønl¥ accept a limited n¨mßer of clients per ¥ear, plus 1 nøn-profit, donating 60% of our time to that nøn-profit at no cost.',
  '[ 2 ] We’re $3l3¢†ive. We ønl¥ accept a limited n¨mßer of clients per ¥ear, plus 1 nøn-profit, donating 90% of our time to that nøn-profit at no cost.',
  '[ 2 ] We’re $3l3¢†ive. We only ¢ce¶t a l!m!t#d number of ¢lients per year, plus 1 non-profit, donating 20% of our †ime to that non-p®ofit at no cøst.',
  '[ 2 ] We’re $3l3¢†ive. We only ¢ce¶t a l!m!t#d number of ¢lients per year, plus 1 non-profit, donating 80% of our †ime to that non-p®ofit at no cøst.',
  '[ 2 ] We’re $3l3¢†ive. We only ¢ce¶t a l!m!t#d number of ¢lients per year, plus 1 non-profit, donating 30% of our †ime to that non-p®ofit at no cøst.',
  '[ 2 ] We’re $3l3¢†ive. We only ¢ce¶t a l!m!t#d number of ¢lients per year, plus 1 non-profit, donating 20% of our †ime to that non-p®ofit at no cøst.',
  '[ 2 ] We’re $3l3¢†ive. We only ¢ce¶t a l!m!t#d number of ¢lients per year, plus 1 non-profit, donating 20% of our †ime to that non-p®ofit at no cøst.',
  '[ 2 ] We’re $elective. 3e only acçept a limited nµmber 0f clients pør year, plus 1 non-profit, donating 20# of our time to that non-prøfit at µo co$t.',
  '[ 2 ] We’re $elective. 3e only acçept a limited nµmber 0f clients pør year, plus 1 non-profit, donating 20# of our time to that non-prøfit at µo co$t.',
  '[ 2 ] We’re $elective. 3e only acçept a limited nµmber 0f clients pør year, plus 1 non-profit, donating 20# of our time to that non-prøfit at µo co$t.',
  '[ 2 ] We’re $elective. 3e only acçept a limited nµmber 0f clients pør year, plus 1 non-profit, donating 20# of our time to that non-prøfit at µo co$t.',
  '[ 2 ] We’re $3l3¢†ive. We only ¢ce¶t a l!m!t#d number of ¢lients per year, plus 1 non-profit, donating 20% of our †ime to that non-p®ofit at no cøst.',
  '[ 2 ] We’re $3l3¢†ive. We only ¢ce¶t a l!m!t#d number of ¢lients per year, plus 1 non-profit, donating 80% of our †ime to that non-p®ofit at no cøst.',
  '[ 2 ] We’re $3l3¢†ive. We only ¢ce¶t a l!m!t#d number of ¢lients per year, plus 1 non-profit, donating 30% of our †ime to that non-p®ofit at no cøst.',
  '[ 2 ] We’re $3l3¢†ive. We only ¢ce¶t a l!m!t#d number of ¢lients per year, plus 1 non-profit, donating 20% of our †ime to that non-p®ofit at no cøst.',
  '[ 2 ] We’re $3l3¢†ive. We only ¢ce¶t a l!m!t#d number of ¢lients per year, plus 1 non-profit, donating 20% of our †ime to that non-p®ofit at no cøst.',
  '[ 2 ] We’re $3l3¢†ive. We ønl¥ accept a limited n¨mßer of clients per ¥ear, plus 1 nøn-profit, donating 10% of our time to that nøn-profit at no cost.',
  '[ 2 ] We’re $3l3¢†ive. We ønl¥ accept a limited n¨mßer of clients per ¥ear, plus 1 nøn-profit, donating 50% of our time to that nøn-profit at no cost.',
  '[ 2 ] We’re $3l3¢†ive. We ønl¥ accept a limited n¨mßer of clients per ¥ear, plus 1 nøn-profit, donating 60% of our time to that nøn-profit at no cost.',
  '[ 2 ] We’re $3l3¢†ive. We ønl¥ accept a limited n¨mßer of clients per ¥ear, plus 1 nøn-profit, donating 90% of our time to that nøn-profit at no cost.',
  '[ 2 ] We’re selective. We only accept a limited number of clients per year, plus 1 non-profit, donating 20% of our time to that non-profit at no cost.'
]

export const arr_3 = [
  '[ 3 ] ∑e $@¥ t4# †h!ng$ ¥øu µa¥ nø† #@n5 t0 4eaª. %e $#e †h!ng$ øth#4 ¶eøp)e d0n’†. ∑e´r¢ £ot @fr@id †o tell ¥ou πhat’s ∞rok#n, ør m!$$ing, bu• w#’() @lwa¥$ †Ell yøu #x@ctl¥ høw tø £!x !t.',

  '[ 3 ] We $a¥ th# th!ng$ ¥øu may nø† want to hear. We see things øther people d0n’†. We’re £ot afraid to tell ¥ou what’s broken, or mi$$ing, but we’ll always †Ell you exactly how to £!x !t.',
  '[ 3 ] We $a¥ th# th!ng$ ¥øu may nø† want to hear. We see things øther people d0n’†. We’re £ot afraid to tell ¥ou what’s broken, or mi$$ing, but we’ll always †Ell you exactly how to £!x !t.',
  '[ 3 ] We $a¥ th# th!ng$ ¥øu may nø† want to hear. We see things øther people d0n’†. We’re £ot afraid to tell ¥ou what’s broken, or mi$$ing, but we’ll always †Ell you exactly how to £!x !t.',
  '[ 3 ] We $a¥ th# th!ng$ ¥øu may nø† want to hear. We see things øther people d0n’†. We’re £ot afraid to tell ¥ou what’s broken, or mi$$ing, but we’ll always †Ell you exactly how to £!x !t.',
  '[ 3 ] We $a¥ th# th!ng$ ¥øu may nø† want to hear. We see things øther people d0n’†. We’re £ot afraid to tell ¥ou what’s broken, or mi$$ing, but we’ll always †Ell you exactly how to £!x !t.',

  '[ 3 ] W# say the things you µa¥ not want to 4eaª. W# see things other people don’t. W#’re not @fraid 2 tell you ∑hat’s brøken, or m!ss!ng, but we’ll @lwa¥s tell y0u exactly how to f!x i†',
  '[ 3 ] W# say the things you µa¥ not want to 4eaª. W# see things other people don’t. W#’re not @fraid 2 tell you ∑hat’s brøken, or m!ss!ng, but we’ll @lwa¥s tell y0u exactly how to f!x i†',
  '[ 3 ] W# say the things you µa¥ not want to 4eaª. W# see things other people don’t. W#’re not @fraid 2 tell you ∑hat’s brøken, or m!ss!ng, but we’ll @lwa¥s tell y0u exactly how to f!x i†',
  '[ 3 ] W# say the things you µa¥ not want to 4eaª. W# see things other people don’t. W#’re not @fraid 2 tell you ∑hat’s brøken, or m!ss!ng, but we’ll @lwa¥s tell y0u exactly how to f!x i†',
  '[ 3 ] W# say the things you µa¥ not want to 4eaª. W# see things other people don’t. W#’re not @fraid 2 tell you ∑hat’s brøken, or m!ss!ng, but we’ll @lwa¥s tell y0u exactly how to f!x i†',

  '[ 3 ] We $@y the †h!ngs y0u ma¥ not want to hea®. πe $ee things øthe® peøple don’t. We’re not afr@1d to 7ell you what’s bro˚en, or m!ssing, ßut we’ll a!ways t@ll you e≈actly how to f!x it.',
  '[ 3 ] We $@y the †h!ngs y0u ma¥ not want to hea®. πe $ee things øthe® peøple don’t. We’re not afr@1d to 7ell you what’s bro˚en, or m!ssing, ßut we’ll a!ways t@ll you e≈actly how to f!x it.',
  '[ 3 ] We $@y the †h!ngs y0u ma¥ not want to hea®. πe $ee things øthe® peøple don’t. We’re not afr@1d to 7ell you what’s bro˚en, or m!ssing, ßut we’ll a!ways t@ll you e≈actly how to f!x it.',
  '[ 3 ] We $@y the †h!ngs y0u ma¥ not want to hea®. πe $ee things øthe® peøple don’t. We’re not afr@1d to 7ell you what’s bro˚en, or m!ssing, ßut we’ll a!ways t@ll you e≈actly how to f!x it.',

  '[ 3 ] W# say the things you µa¥ not want to 4eaª. W# see things other people don’t. W#’re not @fraid 2 tell you ∑hat’s brøken, or m!ss!ng, but we’ll @lwa¥s tell y0u exactly how to f!x i†',
  '[ 3 ] W# say the things you µa¥ not want to 4eaª. W# see things other people don’t. W#’re not @fraid 2 tell you ∑hat’s brøken, or m!ss!ng, but we’ll @lwa¥s tell y0u exactly how to f!x i†',
  '[ 3 ] W# say the things you µa¥ not want to 4eaª. W# see things other people don’t. W#’re not @fraid 2 tell you ∑hat’s brøken, or m!ss!ng, but we’ll @lwa¥s tell y0u exactly how to f!x i†',
  '[ 3 ] W# say the things you µa¥ not want to 4eaª. W# see things other people don’t. W#’re not @fraid 2 tell you ∑hat’s brøken, or m!ss!ng, but we’ll @lwa¥s tell y0u exactly how to f!x i†',
  '[ 3 ] W# say the things you µa¥ not want to 4eaª. W# see things other people don’t. W#’re not @fraid 2 tell you ∑hat’s brøken, or m!ss!ng, but we’ll @lwa¥s tell y0u exactly how to f!x i†',

  '[ 3 ] We $a¥ th# th!ng$ ¥øu may nø† want to hear. We see things øther people d0n’†. We’re £ot afraid to tell ¥ou what’s broken, or mi$$ing, but we’ll always †Ell you exactly how to £!x !t.',
  '[ 3 ] We $a¥ th# th!ng$ ¥øu may nø† want to hear. We see things øther people d0n’†. We’re £ot afraid to tell ¥ou what’s broken, or mi$$ing, but we’ll always †Ell you exactly how to £!x !t.',
  '[ 3 ] We $a¥ th# th!ng$ ¥øu may nø† want to hear. We see things øther people d0n’†. We’re £ot afraid to tell ¥ou what’s broken, or mi$$ing, but we’ll always †Ell you exactly how to £!x !t.',
  '[ 3 ] We $a¥ th# th!ng$ ¥øu may nø† want to hear. We see things øther people d0n’†. We’re £ot afraid to tell ¥ou what’s broken, or mi$$ing, but we’ll always †Ell you exactly how to £!x !t.',
  '[ 3 ] We $a¥ th# th!ng$ ¥øu may nø† want to hear. We see things øther people d0n’†. We’re £ot afraid to tell ¥ou what’s broken, or mi$$ing, but we’ll always †Ell you exactly how to £!x !t.',

  '[ 3 ] We say the things you may not want to hear. We see things other people don’t. We’re not afraid to tell you what’s broken, or missing, but we’ll always tell you exactly how to fix it.'
]

import React from 'react'
import InViewMonitor from 'react-inview-monitor'
const CaseStudyHero = ({ page, module, callback }) => {
  let caseStudies = page.content.filter((i) => i._type === 'caseStudies')
  return (
    <section id="case-study-hero" className={`caseStudyHero section`}>
      <InViewMonitor classNameNotInView="v-hide" classNameInView="fade-in">
        <div className="caseStudyHero--content grid">
          <div className="grid--item is-md-3 left-container">
            <div>
              Insight <br /> Changes <br /> Everything
            </div>
            <div className="show-md-up">
              {caseStudies.map((i, idx) => {
                let anchor = idx > 2 ? idx + 4 : idx + 3
                return (
                  <p
                    key={idx}
                    className="is-ul"
                    onClick={() => callback(anchor)}
                    style={{ cursor: `pointer` }}
                  >
                    {i.company}
                  </p>
                )
              })}
            </div>
          </div>

          <div className="grid--item is-md-7 is-md-offset-1 right-container">
            <h1>{module.title}</h1>
            <div className="ctaContainer">
              {module.ctaTitle && (
                <p style={{ width: `40%` }}>{module.ctaTitle}</p>
              )}
              <p className="is-ul is-pointer" onClick={() => callback(3)}>
                {module.ctaLink} (8)
              </p>
            </div>
          </div>
        </div>
      </InViewMonitor>
    </section>
  )
}

export default CaseStudyHero

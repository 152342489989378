import React from 'react'
import Img from 'gatsby-image'

const CaseStudyPopup = ({ mobile, module, isOpen, close }) => {
  return (
    <section
      className={`caseStudyPopup ${isOpen ? 'show' : ''}`}
      style={{ backgroundColor: module.color }}
    >
      <div className="close-button is-right" onClick={() => close(true)}>
        Close case study (x)
      </div>
      <div className="popup-logo is-ul is-left" onClick={() => close(true)}>
        RDCL
      </div>

      <div className="caseStudyPopup--content">
        <div className="grid title-container">
          <div className="grid--item">
            <h2>
              {module && module.company} <br />– {module && module.subtitle}{' '}
            </h2>
          </div>
        </div>
        <div className="grid content-container">
          {mobile && (
            <div
              style={{ paddingBottom: `1em` }}
              className="grid--item is-md-8 detail-container"
            >
              <p>{module && module.text}</p>
              <p>{module && module.result}</p>
              <div className="close-desktop" onClick={() => close(true)}>
                Close case study (x)
              </div>
            </div>
          )}
          <div className="grid--item is-md-4 press-container">
            <p className="press-title">Press</p>
            {module &&
              module.press.map((i, idx) => {
                return (
                  <a
                    key={idx}
                    href={i.url}
                    className="press-item"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p>
                      {i.title}, {i.publisher}
                    </p>
                  </a>
                )
              })}
          </div>
          {!mobile && (
            <div className="grid--item is-md-8 detail-container">
              <p>{module && module.text}</p>
              <p>{module && module.result}</p>
              <div className="close-desktop" onClick={() => close(true)}>
                Close case study (x)
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default CaseStudyPopup

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const SoftFooter = ({ page, module }) => {
  const data = useStaticQuery(graphql`
    {
      advisorgroup: file(relativePath: { eq: "client/advisorgroup.png" }) {
        publicURL
      }
      ariel: file(relativePath: { eq: "client/ariel.png" }) {
        publicURL
      }
      cerity: file(relativePath: { eq: "client/cerity.png" }) {
        publicURL
      }
      cetera: file(relativePath: { eq: "client/cetera.png" }) {
        publicURL
      }
      fairholme: file(relativePath: { eq: "client/fairholme.png" }) {
        publicURL
      }
      hightower: file(relativePath: { eq: "client/hightower.png" }) {
        publicURL
      }
      lightyearcapital: file(
        relativePath: { eq: "client/lightyearcapital.png" }
      ) {
        publicURL
      }
      tiaa: file(relativePath: { eq: "client/tiaa.png" }) {
        publicURL
      }
      unitedcapital: file(relativePath: { eq: "client/unitedcapital.png" }) {
        publicURL
      }
      janushenderson: file(relativePath: { eq: "client/janushenderson.png" }) {
        publicURL
      }
      comply: file(relativePath: { eq: "client/comply.png" }) {
        publicURL
      }
      flexshares: file(relativePath: { eq: "client/flexshares.png" }) {
        publicURL
      }
      fmg: file(relativePath: { eq: "client/fmg.png" }) {
        publicURL
      }
      thrivent: file(relativePath: { eq: "client/thrivent.jpg" }) {
        publicURL
      }
      wealthenhancementgroup: file(
        relativePath: { eq: "client/wealthenhancementgroup.png" }
      ) {
        publicURL
      }
    }
  `)
  const imgList = [
    data.unitedcapital,
    data.advisorgroup,
    data.cetera,
    data.wealthenhancementgroup,
    data.ariel,
    data.cerity,
    data.fairholme,
    data.hightower,
    data.janushenderson,
    data.lightyearcapital,
    data.tiaa,
    data.comply,
    data.fmg,
    data.thrivent,
    data.flexshares,
  ]
  return (
    <div className="section soft-footer">
      <div className="grid soft-footer--content">
        <div className="grid--item is-12 is-md-2 left-col">
          <p>Our recent partnerships.</p>
        </div>
        <div className="grid--item is-12 is-md-8 is-md-offset-2 right-col">
          <div className="grid" style={{ height: `100%` }}>
            {imgList.map((i, idx) => {
              return (
                <div
                  key={idx}
                  className="grid--item is-4 is-md-4 icon-container"
                >
                  <img id={`id_${idx}`} src={i.publicURL} alt="client-image" />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SoftFooter

import React, { useState } from 'react'
import BlockContent from '@sanity/block-content-to-react'
import { Link } from 'gatsby'

import { imageUrlFor } from '../../lib/image-url'

import CaseStudies from '../modules/caseStudies'
import CaseStudyGap from '../modules/caseStudyGap'
import CaseStudyHero from '../modules/caseStudyHero'
import Contact from '../modules/contact'
import ServicesHero from '../modules/servicesHero'
import WhoWeAre from '../modules/whoWeAre'
import HeroVideo from '../modules/heroVideo'
import Hero from '../modules/hero'
import News from '../modules/news'
import SoftFooter from '../modules/softFooter'
import Services from '../modules/services'

const Module = ({
  page,
  module,
  mobile,
  callback,
  jump,
  popupOpen,
  isScrollJacked,
  index,
}) => {
  const type = module._type
  function _setPopup(e) {
    callback(e)
  }
  function _jumpToSlide(idx) {
    jump(idx)
  }
  function _setBackToNav(i) {
    jump(2)
  }
  switch (type) {
    case 'caseStudyGap':
      return (
        <CaseStudyGap page={page} module={module} callback={_jumpToSlide} />
      )
    case 'caseStudyHero':
      return (
        <CaseStudyHero page={page} module={module} callback={_jumpToSlide} />
      )
    case 'servicesHero':
      return (
        <ServicesHero
          page={page}
          module={module}
          callback={_jumpToSlide}
          mobile={mobile}
        />
      )
    case 'contact':
      return <Contact page={page} module={module} mobile={mobile} />
    case 'whoWeAre':
      return <WhoWeAre page={page} module={module} mobile={mobile} />
    case 'caseStudies':
      return (
        <CaseStudies
          page={page}
          module={module}
          popupOpen={popupOpen}
          callback={_setPopup}
          backToTop={_setBackToNav}
          mobile={mobile}
          index={index}
        />
      )
    case 'news':
      return <News mobile={mobile} page={page} module={module} />
    case 'hero':
      return (
        <Hero
          mobile={mobile}
          page={page}
          module={module}
          isScrollJacked={isScrollJacked}
        />
      )
    case 'heroVideo':
      return <HeroVideo mobile={mobile} page={page} module={module} />
    case 'softFooter':
      return <SoftFooter mobile={mobile} page={page} module={module} />
    case 'services':
      return (
        <Services
          page={page}
          mobile={mobile}
          module={module}
          popupOpen={popupOpen}
          backToNav={_setBackToNav}
          callback={_setPopup}
        />
      )
    default:
      return null
  }
  return ''
}

export default Module

import React from 'react'
import InViewMonitor from 'react-inview-monitor'
import { motion, AnimatePresence } from 'framer-motion'

const CaseStudies = ({
  page,
  module,
  callback,
  popupOpen,
  backToTop,
  index
}) => {
  let ct = index < 5 ? index - 1 : index - 2

  return (
    <section className={`section caseStudies`}>
      <InViewMonitor classNameNotInView="v-hide" classNameInView="fade-in">
        <AnimatePresence exitBeforeEnter>
          {!popupOpen && (
            <motion.div
              key={module.key}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.15 }}
            >
              <div className={`caseStudies--content`}>
                <div className="caseStudies--container grid">
                  <div className="count-container grid--item is-12 hide-md-up">
                    <p>{ct}/8</p>
                    <p>{module.subtitle}</p>
                    <p
                      style={{ cursor: `pointer` }}
                      className="is-ul cta"
                      onClick={() => backToTop(true)}
                    >
                      Back to top
                    </p>
                  </div>
                  <div className="quote-container grid--item is-md-8 is-md-offset-4">
                    <h2>{module.quote}</h2>
                  </div>
                  <div className="count-container grid--item is-md-4 show-md-up is-flex">
                    <p>{ct}/8</p>
                    <p>{module.subtitle}</p>
                    <p
                      style={{ cursor: `pointer` }}
                      className="is-ul cta"
                      onClick={() => backToTop(true)}
                    >
                      Back to top
                    </p>
                  </div>

                  <div className="detail-container grid--item is-md-8 ">
                    <p>{module.name}</p>
                    <p>
                      {module.title}, {module.company}
                    </p>
                    <p
                      style={{ cursor: `pointer` }}
                      className="is-ul cta"
                      onClick={() => callback(module)}
                    >
                      Expand case study (+)
                    </p>
                  </div>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </InViewMonitor>
    </section>
  )
}

export default CaseStudies

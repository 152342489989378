import React, { useState } from 'react'
import { useStaticQuery } from 'gatsby'
import InViewMonitor from 'react-inview-monitor'
import Parallax from 'react-rellax'
import { arr_1, arr_2, arr_3 } from '../../lib/glitchText'

const WhoWeAre = ({ mobile }) => {
  const data = useStaticQuery(graphql`
    {
      imgHero: file(relativePath: { eq: "who-we-are/img-hero.png" }) {
        publicURL
      }
      imgBody: file(relativePath: { eq: "who-we-are/img-body.png" }) {
        publicURL
      }
      imgFooter: file(relativePath: { eq: "who-we-are/img-footer.png" }) {
        publicURL
      }
    }
  `)
  const { imgHero, imgBody, imgFooter } = data

  let [phrase_1, setPhrase_1] = useState(
    '[ 1 ] We don’t do research as usual. We analyze human behavior using psychology, anthropology, and sociology to unlock meaningful insights that move people.'
  )
  let [phrase_2, setPhrase_2] = useState(
    '[ 2 ] We’re selective. We only accept a limited number of clients per year, plus 1 non-profit, donating 20% of our time to that non-profit at no cost.'
  )
  let [phrase_3, setPhrase_3] = useState(
    '[ 3 ] We say the things you may not want to hear. We see things other people don’t. We’re not afraid to tell you what’s broken, or missing, but we’ll always tell you exactly how to fix it.'
  )

  function _shuffleText(item, index) {
    item.forEach((i, idx) => {
      setTimeout(() => {
        index === 1 && setPhrase_1(item[idx])
        index === 2 && setPhrase_2(item[idx])
        index === 3 && setPhrase_3(item[idx])
      }, idx * 25)
    })
  }

  function _headerInView(item, index) {
    _shuffleText(item, index)
  }

  let [hover, setHover] = useState(null)

  return (
    <section className="whoWeAre">
      <div className="whoWeAre--content">
        {/* SECTION 1 */}
        <div className="grid section section--one">
          <div className="grid--item is-md-3 left-col text text--eyebrow">
            <p>Our Manifesto</p>
            {!mobile && (
              <p className={`footnote ${hover === 'one' ? 'is-active' : ''}`}>
                <InViewMonitor
                  repeatOnInView
                  onInView={() => _headerInView(arr_1, 1)}
                >
                  <span>{phrase_1}</span>
                </InViewMonitor>
              </p>
            )}
          </div>
          <div className="grid--item is-md-8 is-md-offset-1 text text--body">
            <h2>The financial world needs new ideas, and meaningful change.</h2>
            <h2>
              We uncover radical insights that propel companies into uncharted
              territories.
              <sup
                onMouseOver={() => setHover('one')}
                onMouseOut={() => setHover(null)}
              >
                [ 1 ]
              </sup>
            </h2>
          </div>
        </div>

        {/* SECTION 2 */}
        <div className="grid section section--two">
          <div className="grid--item is-md-11 title-col">
            <h2>
              We work with those who share our mission to move the industry in a
              more human-centered direction.
              <sup
                onMouseOver={() => setHover('two')}
                onMouseOut={() => setHover(null)}
              >
                [ 2 ]
              </sup>
            </h2>
          </div>
          <div className="grid--item is-md-3 left-col">
            {!mobile && (
              <p className={`footnote ${hover === 'two' ? 'is-active' : ''}`}>
                <InViewMonitor
                  repeatOnInView
                  onInView={() => _headerInView(arr_2, 2)}
                >
                  <span>{phrase_2}</span>
                </InViewMonitor>
              </p>
            )}
          </div>
          <div className="grid--item is-md-7 is-md-offset-1">
            <h2>Our approach is not conventional.</h2>
          </div>
        </div>

        {/* SECTION 3 */}
        <div className="grid section section--three">
          <div className="grid--item is-md-3 left-col">
            {!mobile && (
              <p className={`footnote ${hover === 'three' ? 'is-active' : ''}`}>
                <InViewMonitor
                  repeatOnInView
                  onInView={() => _headerInView(arr_3, 3)}
                >
                  <span>{phrase_3}</span>
                </InViewMonitor>
              </p>
            )}
          </div>
          <div className="grid--item is-md-7 is-md-offset-1 right-col">
            <h2>
              We are not for everyone.
              <sup
                onMouseOver={() => setHover('three')}
                onMouseOut={() => setHover(null)}
              >
                [ 3 ]
              </sup>
              <br />
              <br />
              We are radical.
            </h2>
          </div>
        </div>

        {mobile && (
          <div className="grid">
            <div className="grid--item is-6">
              <p className={`pt-5`}>
                <InViewMonitor onInView={() => _headerInView(arr_1, 1)}>
                  <span>{phrase_1}</span>
                </InViewMonitor>
              </p>
            </div>
            <div className="grid--item is-6">
              <p className={`pt-5`}>
                <InViewMonitor onInView={() => _headerInView(arr_2, 2)}>
                  <span>{phrase_2}</span>
                </InViewMonitor>
              </p>
            </div>
            <div className="grid--item is-12">
              <p className={`pt-5`}>
                <InViewMonitor onInView={() => _headerInView(arr_3, 3)}>
                  <span>{phrase_3}</span>
                </InViewMonitor>
              </p>
            </div>
          </div>
        )}

        {/* IMG Hero Section */}
        <div className="grid section section--heroImg">
          <div className="image-container">
            <img
              className="image image--hero"
              src={imgHero.publicURL}
              alt="RDCL Barnaby and Alyssa"
            />
          </div>
        </div>

        {/* SECTION 4 */}
        <div className="grid section  section--four">
          <div className="grid--item is-md-4 left-col">
            <p>How We Work</p>
            {!mobile && (
              <div className="image-container">
                <img
                  className="image image--bodyImg"
                  src={imgBody.publicURL}
                  alt="Chairs around a table"
                />
              </div>
            )}
          </div>
          <div className="grid--item is-md-8">
            <div className="grid">
              <div className="grid--item is-md-6">
                <h2>Data doesn't interpret itself.</h2>
              </div>
              <div className="grid--item is-md-5 is-md-offset-1">
                <p>
                  The problems our clients face are not methodological. They’re
                  interpretive. Anybody can design a research study and collect
                  data. But data doesn’t interpret itself. We’re able to think
                  interpretively about human behavior using the social
                  sciences–behavioral finance, psychoanalysis, anthropology,
                  sociology–to challenge industry assumptions and create big
                  opportunities for our clients.
                </p>
              </div>
              <div className="grid--item is-md-6 pt-md-5">
                <h2>Bring in the specialists.</h2>
              </div>
              <div className="grid--item is-md-5 is-md-offset-1 pt-md-5">
                <p>
                  While other firms act as data vendors, RDCL stands
                  shoulder-to-shoulder with your team to solve your most
                  strategic marketing challenges. Our partnerships are exclusive
                  (and intimate) because they’re focused on problems at the
                  cutting edge of culture. We’re not generalists, so we’ll never
                  promise to do it all. And we’ll never pass you on to junior
                  teams. We’re a catalytic partner fueling the growth of the
                  next industry game-changers. If you aren’t sure we’re the
                  right fit, we’re probably not.
                </p>
              </div>
            </div>
          </div>
          {!!mobile && (
            <div className="grid--item mobile-body-image-col">
              <div className="image-container">
                <img
                  className="image image--bodyImg"
                  src={imgBody.publicURL}
                  alt="Chairs around a table"
                />
              </div>
            </div>
          )}
        </div>

        {/* SECTION 6 */}
        <div className="grid section section--six">
          <div className="grid--item is-md-3 text text--eyebrow">
            <p>Our Story</p>
          </div>
          <div className="grid--item is-md-12 left-col text text--header">
            <h2>
              Incubated at the University of Chicago, RDCL is a social
              science-based research firm located in Southern California.
            </h2>
          </div>
          <div className="grid--item is-md-3 is-md-offset-4 text text--subhead">
            <p>
              We combine academic rigour with years of industry experience to
              develop brands grounded in how people actually think and feel
              about financial products and services. We connect the dots from
              insight to activation, and most importantly, inspire our client
              partners to think radically.
            </p>
          </div>
          <div className="image-container">
            <img
              className="image image--footerImg"
              src={imgFooter.publicURL}
              alt="RDCL Barnaby and Alyssa"
            />
          </div>
        </div>

        {/* SECTION 7 */}
        <div className="grid section section--seven">
          <div className="grid--item is-md-2">
            <p>Leadership</p>
          </div>
          <div className="grid--item is-md-3 is-md-offset-2 pt-sm-3 pt-md-0">
            <p className="is-ul">Barnaby B. Riedel, Ph.D</p>
            <p>Co-Founder, Research</p>
            <p className="pt-sm-3 pt-md-5">
              Barnaby leads RDCL’s research department, using social science
              methodologies and theoretical perspectives to illuminate the human
              side of the financial industry. Prior to founding RDCL, Barnaby
              taught at the University of Chicago as the Earl S. Johnson
              Instructor in the Division of the Social Sciences. His approach to
              research is creative, interdisciplinary and contrarian - drawing
              on everything from evolutionary psychology to narrative studies
              and cultural anthropology. Whatever helps him challenge
              assumptions, push the limits of what’s known and uncover deep
              truths below the surface of everyday life. As an avid trail
              runner, mountain biker, and triathlete, you can find him in the
              pre-dawn hours getting his miles in.
            </p>
          </div>
          <div className="grid--item is-md-3 is-md-offset-1 pt-sm-5">
            <p className="is-ul">Alyssa J. Riedel, M.A.</p>
            <p>Co-Founder, Brand</p>
            <p className="pt-sm-3 pt-md-5">
              Alyssa leads RDCL’s brand department, connecting the dots from
              insight to activation with strategic solutions that consistently
              challenge the status quo. She’s spent the last 9 years working in
              the financial services industry, earning her Series 6 and Series
              63 licenses, and serving on the board of directors for the
              Financial Communications Society, Chicago Chapter. With over 16
              years of marketing experience, her expertise runs the gamut from
              finance to design to communications to executive leadership. She
              has a modernist’s eye that she applies to her home and her work,
              constantly eliminating the superfluous until only the core ideals
              remain, and a self-discipline that she channels into her daily
              Bikram yoga and Peloton practice.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhoWeAre

import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import InViewMonitor from 'react-inview-monitor'
import { motion, AnimatePresence } from 'framer-motion'

// site components
import Dropdown from './dropdown'

const Header = ({ active, expanded = false, mobile, jump, location }) => {
  const data = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "logos/wordmark_black.svg" }) {
        publicURL
      }
      menu: sanityMenu(slug: { current: { eq: "header" } }) {
        title
        items {
          ... on SanityNavDropdown {
            title
            items {
              ... on SanityNavLink {
                title
                isURL
                link
                text_link
                isButton
              }
              ... on SanityNavPage {
                title
                page {
                  slug {
                    current
                  }
                }
                isButton
              }
            }
          }
          ... on SanityNavLink {
            title
            isURL
            link
            text_link
            isButton
          }
          ... on SanityNavPage {
            title
            page {
              slug {
                current
              }
            }
            isButton
          }
        }
      }
    }
  `)

  // page data
  const { logo, menu } = data

  // menu state
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const isOpen = isMenuOpen

  const toggleMenu = (open) => {
    setIsMenuOpen(open)
    if (typeof document !== `undefined`) {
      document.body.classList.toggle('nav-open', open)
    }
  }

  return (
    <>
      <a href="#content" className="skip-link">
        Skip to Content
      </a>
      <header className="header">
        <div className="header--wrapper">
          <div className={`header--content grid ${expanded ? 'expanded' : ''}`}>
            <div className="grid--item is-md-3">
              {active === '/' ? (
                <Link
                  onClick={() => {
                    toggleMenu(false)
                    jump(1)
                  }}
                  to="/"
                  className={`logo is-active`}
                >
                  <span>
                    <InViewMonitor repeatOnInView>
                      <span className="is-ul">RDCL</span>
                      <AnimatePresence exitBeforeEnter>
                        {expanded && (
                          <motion.span
                            key={expanded}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            className="show-md-up is-inline"
                          >
                            {' '}
                            is a social science-based research firm dedicated
                            exclusively to the most forward-thinking financial
                            companies.
                          </motion.span>
                        )}
                      </AnimatePresence>
                    </InViewMonitor>
                  </span>
                </Link>
              ) : (
                <Link
                  onClick={() => toggleMenu(false)}
                  to="/"
                  className={`logo`}
                >
                  <span className="non-active">RDCL</span>
                </Link>
              )}
            </div>

            {menu && (
              <div className="grid--item is-md-8 is-md-offset-1">
                <nav className="main-navigation" role="navigation">
                  <button
                    onClick={() => toggleMenu(!isOpen)}
                    className="menu-toggle"
                    aria-expanded={isOpen ? 'true' : 'false'}
                    aria-label="Toggle Menu"
                  >
                    <p>Menu {isOpen ? '-' : '+'}</p>
                  </button>

                  <ul className="mobile-menu-list">
                    {menu.items.map((item, key) => {
                      const hasDropdown = !!item.items
                      if (hasDropdown) {
                        const dropdown = item.items
                        const activeDropdown =
                          dropdown.filter(
                            (item) =>
                              item.page && item.page.slug.current === active
                          ).length > 0

                        return (
                          <li
                            key={key}
                            className={activeDropdown ? 'is-active' : ''}
                          >
                            <Dropdown
                              title={item.title}
                              items={dropdown}
                              toggleMenu={toggleMenu}
                            />
                          </li>
                        )
                      } else {
                        const isLink = !!item.page
                        const activePage =
                          item.page && item.page.slug.current === active
                        // const jumpLink = item.page.slug === 'what-we-do'
                        let isHomePage = location.pathname === '/'
                        let noHash = location.hash !== '#case-studies'
                        let homeNoHash = isHomePage && noHash
                        let csLink = homeNoHash ? (
                          <div
                            onClick={() => {
                              jump(2)
                              toggleMenu(false)
                            }}
                            className={`is-link internal-link`}
                          >
                            {mobile && <h2 className="mb-0">{item.title}</h2>}
                            {!mobile && item.title}
                          </div>
                        ) : (
                          <Link to="/#case-studies" className="is-link">
                            {mobile && <h2 className="mb-0">{item.title}</h2>}
                            {!mobile && item.title}
                          </Link>
                        )
                        return (
                          <li key={key}>
                            {!isLink ? (
                              csLink
                            ) : (
                              <Link
                                onClick={() => {
                                  toggleMenu(false)
                                  jump(1)
                                }}
                                to={`/${item.page.slug.current}/`}
                                className={`is-link is-ul ${
                                  item.isButton ? 'btn' : ''
                                } ${activePage ? 'is-active' : ''}`}
                              >
                                {mobile && (
                                  <h2 className="mb-0">{item.title}</h2>
                                )}
                                <span>{!mobile && item.title}</span>
                              </Link>
                            )}
                          </li>
                        )
                      }
                    })}
                    {mobile && (
                      <img
                        src={logo.publicURL}
                        alt="RDCL Black Wordmark"
                        style={{ marginTop: `auto` }}
                      />
                    )}
                  </ul>
                  <div className="main-navigation--overlay" />
                </nav>
              </div>
            )}
          </div>
        </div>
      </header>
    </>
  )
}

export default Header

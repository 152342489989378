import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

const footer = ({ homePage, jump }) => {
  const data = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "logos/wordmark_black.svg" }) {
        publicURL
      }
    }
  `)

  const { logo } = data
  return (
    <footer className="section footer" role="contentinfo">
      <div className="footer--wrapper">
        <div className="footer--content">
          <div className="footer--main">
            <img
              src={logo.publicURL}
              alt="RDCL Footer Logo"
              className="footer-logo"
            />
            <div className="footer-link-container grid">
              <div className="grid--item is-6 d-flex flex-col justify-between">
                <p className="is-ul">
                  <Link to="/">RDCL</Link>
                </p>
                <p className="">
                  Orange County <br />
                  California, US
                </p>
                <a className="" href="tel:9494335977">
                  +1 949 433 5977
                </a>
              </div>
              <div className="grid--item is-6 d-flex flex-col justify-between">
                <span>
                  <Link className="is-ul" to="/who-we-are">
                    Who We Are
                  </Link>
                </span>

                <span>
                  <Link className="is-ul" to="/services">
                    Services
                  </Link>
                </span>

                <span>
                  <Link className="is-ul" to="/clients">
                    Clients
                  </Link>
                </span>

                {homePage ? (
                  <span>
                    <div
                      className="is-ul"
                      to="/clients"
                      onClick={() => jump(2)}
                      style={{ cursor: `pointer` }}
                    >
                      Work
                    </div>
                  </span>
                ) : (
                  <span>
                    <Link className="is-ul" to="/#case-studies">
                      Work
                    </Link>
                  </span>
                )}

                <span>
                  <Link className="is-ul" to="/news">
                    News
                  </Link>
                </span>

                <span>
                  <Link className="is-ul" to="/contact">
                    Contact
                  </Link>
                </span>

                <span>
                  <a className="is-ul" href="mailto:jobs@rdcl.financial">
                    Jobs
                  </a>
                </span>
              </div>
            </div>
            <div className="footer-meta-container grid">
              <div className="grid--item is-6">
                <p>&copy; {new Date().getFullYear()} RDCL</p>
              </div>
              <div className="grid--item is-6 site-credit">
                <a
                  href="https://alright.studio"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="is-ul"
                >
                  Site Credit
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default footer

import React from 'react'

const CaseStudyGap = ({ module, page, callback }) => {
  return (
    <section className={`caseStudyGap section`}>
      <div className="caseStudyGap--content grid">
        <div className="grid--item is-md-7 is-md-offset-4 container">
          <h1>{module.title}</h1>
          <div className="ctaContainer">
            <p>{module.ctaTitle}</p>
            {!!module.ctaLink && (
              <p className="is-ul is-pointer" onClick={() => callback(7)}>
                {module.ctaLink} (5)
              </p>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default CaseStudyGap

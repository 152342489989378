import React, { useState, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Modal from 'react-modal'
import ReactPlayer from 'react-player'
import Vimeo from '@u-wave/react-vimeo'

const HeroVideo = ({ module }) => {
  let [mobile, setMobile] = useState(null)
  const modalVideo = 'https://player.vimeo.com/779399002?autoplay=1'
  const bgVideo = '467919517'
  const data = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "logos/wordmark_black.svg" }) {
        publicURL
      }
      triangle: file(relativePath: { eq: "shapes/triangle.svg" }) {
        publicURL
      }
    }
  `)

  useEffect(() => {
    if (typeof document !== `undefined`) {
      setMobile(document.documentElement.clientWidth <= 1024)
    }
  }, [mobile])

  const { logo, triangle } = data
  const { title, video } = module
  const [videoOpen, setVideoOpen] = useState(false)

  const desktopVideoRender = (
    <Modal
      ariaHideApp={false}
      isOpen={videoOpen}
      onRequestClose={() => setVideoOpen(false)}
    >
      <div className="video-container-desktop show-md-up">
        <p className="close-button" onClick={() => setVideoOpen(false)}>
          [close]
        </p>
        <ReactPlayer
          playing={videoOpen}
          width="100%"
          height="100%"
          className="react-player"
          url={modalVideo}
        />
      </div>
    </Modal>
  )

  const mobileVideoRender = (
    <div className={`video-container-mobile ${videoOpen ? 'show' : ''} `}>
      <ReactPlayer
        playing={videoOpen}
        onPause={() => setVideoOpen(false)}
        width="100%"
        height="100%"
        className="react-player"
        url={modalVideo}
      />
    </div>
  )
  return (
    <section className="section heroVideo">
      <div className="heroVideo--content">
        {mobile ? mobileVideoRender : desktopVideoRender}
        <img
          src={logo.publicURL}
          alt="RDCL Wordmark Black"
          className="wordmark"
        />
        <div className="media-container">
          <div className="heroVideo-title">
            <h1>{title}</h1>
          </div>
          <div className="play-more-button">
            <p
              className="is-ul text-black bg-yellow"
              onClick={() => setVideoOpen(true)}
            >
              Watch film (1:16){' '}
              <img src={triangle.publicURL} alt="triangle shape" />
            </p>
          </div>
          <div className="video-container">
            <div className="overlay" />
            <div className="video-content">
              {video && (
                <Vimeo
                  video={bgVideo}
                  id="RDCL"
                  width="100%"
                  height="100%"
                  autoplay={true}
                  autopause={false}
                  showTitle={false}
                  paused={false}
                  background={true}
                  loop={true}
                  muted={true}
                  controls={false}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroVideo

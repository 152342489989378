import React, { useState } from 'react'
import { Link } from 'gatsby'
import { motion } from 'framer-motion'

const Dropdown = ({ title, items, toggleMenu }) => {
  const [open, setOpen] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const isOpen = open

  const handleBlur = e => {
    const currentTarget = e.currentTarget

    // Check the newly focused element in the next tick of the event loop
    setTimeout(() => {
      // Check if the new activeElement is a child of the original container
      if (!currentTarget.contains(document.activeElement)) {
        // You can invoke a callback or add custom logic here
        setOpen(false)
      }
    }, 0)
  }

  const toggleDropdown = toggle => {
    setOpen(toggle)
    setExpanded(!expanded)
  }

  const variants = {
    expanded: {
      height: 'auto'
    },
    collapsed: {
      height: 0
    }
  }

  return (
    <div
      className={`dropdown${isOpen ? ' is-open is-expanded"' : ''}`}
      onMouseLeave={() => setOpen(false)}
      onMouseEnter={() => setOpen(true)}
      onBlur={handleBlur}
    >
      <button className="dropdown--btn" onClick={() => toggleDropdown(!isOpen)}>
        {title}
      </button>
      <motion.div
        className="dropdown--content"
        initial={expanded ? 'expanded' : 'collapsed'}
        animate={expanded ? 'expanded' : 'collapsed'}
        variants={variants}
      >
        <ul className="dropdown--nav">
          {items.map((item, key) => {
            const isLink = !!item.link
            return (
              <li key={key}>
                {isLink ? (
                  <a href={item.link} target="_blank" rel="noopener noreferrer">
                    {item.title}
                  </a>
                ) : (
                  <Link
                    onClick={() =>
                      toggleMenu ? toggleMenu(false) : () => false
                    }
                    to={`/${item.page.slug.current}/`}
                  >
                    {item.title}
                  </Link>
                )}
              </li>
            )
          })}
        </ul>
      </motion.div>
    </div>
  )
}

export default Dropdown

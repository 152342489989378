import React from 'react'
import InViewMonitor from 'react-inview-monitor'

const ServicesHero = ({ module, page, callback, mobile }) => {
  const services = [
    {
      title: 'Brand Strategy',
      index: 3
    },
    {
      title: 'Client Experience (CX)',
      index: 4
    },
    {
      title: 'Advertising & Recruiting',
      index: 5
    },
    {
      title: 'Thought Leadership',
      index: 6
    },
    {
      title: 'Message Testing',
      index: 7
    },
    {
      title: 'Competetive Benchmarking',
      index: 8
    }
  ]

  return (
    <section className={`servicesHero section`}>
      <InViewMonitor classNameNotInView="v-hide" classNameInView="fade-in">
        <div className="servicesHero--content grid">
          <div className="grid--item is-md-3 left-col">
            {!mobile && module.subtitle}
            {!mobile && (
              <div className="serviceList">
                <p>Services:</p>
                <ul>
                  {services.map((i, idx) => {
                    return (
                      <li onClick={() => callback(i.index)} key={idx}>
                        {i.title}
                      </li>
                    )
                  })}
                </ul>
              </div>
            )}
          </div>
          <div className="grid--item is-md-8 is-md-offset-1 right-col">
            <h1>{module.title}</h1>
            {mobile && <p className="py-4">{module.subtitle}</p>}

            <div
              className="ctaContainer is-ul is-pointer"
              onClick={() => callback(3)}
            >
              View service details ({services.length})
            </div>
          </div>
        </div>
      </InViewMonitor>
    </section>
  )
}

export default ServicesHero

import React from 'react'
import InViewMonitor from 'react-inview-monitor'

const News = ({ page, module }) => {
  let { title, snippet, url, publisher } = module
  let { src } = module.shape.asset.fluid
  let { alt } = module.shape
  return (
    <section className="news">
      <InViewMonitor classNameNotInView="v-hide" classNameInView="fade-in">
        <div className="news--content grid">
          <div className="grid--item is-12 is-md-2 img-container">
            <img src={src} alt={alt} />
          </div>
          <div className="grid--item is-12 is-md-4 is-md-offset-2">
            <a target="_blank" rel="noreferrer noopener" href={url}>
              <h2>{publisher}</h2>
            </a>
          </div>
          <div className="grid--item is-12 is-md-3">
            <a
              target="_blank"
              rel="noreferrer noopener"
              href={url}
              className="is-ul"
            >
              {title}
            </a>
            <div className="pt-3 pt-md-5">{module.snippet}</div>
          </div>
        </div>
      </InViewMonitor>
    </section>
  )
}

export default News

import React from 'react'
import InViewMonitor from 'react-inview-monitor'
import { motion, AnimatePresence } from 'framer-motion'

const Services = ({ page, module, callback, popupOpen, mobile, backToNav }) => {
  let { description, caseStudies, title } = module

  return (
    <div className="section services">
      <InViewMonitor classNameNotInView="v-hide" classNameInView="fade-in">
        <AnimatePresence exitBeforeEnter>
          {!popupOpen && (
            <motion.div
              key={module.key}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.15 }}
            >
              <div className="services--content">
                <div className="grid">
                  <div className="grid--item is-md-8">
                    <h2>{title}</h2>
                  </div>
                </div>
                <div className="grid">
                  {!mobile && (
                    <div className="grid--item is-md-3">
                      <p className="pb-3">Case Studies:</p>
                      {caseStudies.map((i, idx) => {
                        return (
                          <p
                            key={idx}
                            onClick={() => callback(i)}
                            className="is-ul"
                            style={{ cursor: `pointer` }}
                          >
                            {i.company}
                          </p>
                        )
                      })}
                    </div>
                  )}

                  <div className="grid--item is-md-7 is-md-offset-1">
                    {description}
                  </div>
                </div>
                <div className="grid">
                  <div className="grid--item is-md-5 is-md-offset-4 d-flex align-end">
                    <p
                      className="is-ul"
                      onClick={() => backToNav(true)}
                      style={{ cursor: `pointer` }}
                    >
                      Back to Services
                    </p>
                  </div>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </InViewMonitor>
    </div>
  )
}

export default Services

import React from 'react'
import { useStaticQuery } from 'gatsby'

const Hero = ({ page, module, isScrollJacked }) => {
  const data = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "logos/wordmark_black.svg" }) {
        publicURL
      }
    }
  `)

  const { logo } = data
  const { title, shape } = module

  return (
    <section className="section hero">
      <div className="hero--content grid">
        <div className="grid--item is-md-3 left-col desktop-view">
          <img
            src={logo.publicURL}
            alt="RDCL Wordmark Black"
            className="wordmark"
          />
        </div>
        <div className="grid--item is-md-8 is-md-offset-1 right-col">
          <div
            className={`shape-container anchor-top`}
          >
            <img src={shape.asset.fluid.src} alt="Hero Rectangle Blue" />
          </div>

          <div className="heroVideo-title">
            <h1>{title}</h1>
          </div>
        </div>
        <div className="grid--item is-12 left-col mobile-view">
          <img
            src={logo.publicURL}
            alt="RDCL Wordmark Black"
            className="wordmark"
          />
        </div>
      </div>
    </section>
  )
}

export default Hero

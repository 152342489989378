import React from 'react'
import Img from 'gatsby-image'
import { useStaticQuery } from 'gatsby'

const Contact = ({ page, module }) => {
  const data = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "logos/wordmark_black.svg" }) {
        publicURL
      }
    }
  `)

  const { logo } = data
  const { text, name, jobTitle, email, phone, image } = module
  return (
    <section className="contact">
      <div className="contact--content grid">
        <div className="grid--item is-md-3 left-col">
          <img
            src={logo.publicURL}
            alt="RDCL Wordmark Black"
            className="w-100"
          />
        </div>
        <div className="grid--item is-md-8 is-md-offset-1 right-col">
          <h2 style={{ marginRight: `16.66%` }}>{text}</h2>
          <div className="grid pt-5">
            <div className="grid--item is-5 content-col">
              <p>{name}</p>
              <p>{jobTitle}</p>
              <p>
                <a href={`phone:${phone}`}>{phone}</a>
              </p>
              <p>
                <a href={`mailto:${email}`}>{email}</a>
              </p>
            </div>
            <div className="grid--item is-7 img-col">
              {image && (
                <img src={module.image.asset.fluid} alt={module.image.alt} />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact
